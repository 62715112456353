@import 'utils/vars.scss';

.commoditie-details {
  margin-top: 24px;
  margin-bottom: 100px;
  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: none;
    cursor: pointer;
    margin-bottom: 24px;
  }
  .details {
    display: flex;
    gap: 24px;

    @media screen and (max-width: 666px) {
      flex-wrap: wrap;
    }
    &__img {
      min-width: 352px;
      height: 352px;
      padding: 35px;
      border: 1px solid #666666;
      border-radius: 8px;
      background-color: #ebf2e9;
      @media screen and (max-width: 1000px) {
        min-width: 250px;
        height: 250px;
      }
      @media screen and (max-width: 666px) {
        min-width: 100%;
        height: 300px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &__title {
      color: $text-black;
      font-family: 'Playfair Display', serif;
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 4px;
    }
    &__subtitle {
      color: #666666;
      font-weight: 700;
      font-family: 'Fira Sans', sans-serif;
      font-size: 18px;
      line-height: 24px;
      background-color: $main-bg;
      position: absolute;
      top: -13px;
      left: 20px;
      padding: 0px 10px;
    }
    &__item {
      position: relative;
      padding: 24px;
      border: 0.5px solid #666666;
      border-radius: 8px;
      p {
        font-weight: 400;
        color: $text-black;
        font-size: 18px;
        line-height: 24px;
      }
    }
    &__spec {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &__info {
      display: flex;
      align-items: center;
    }
    &__label {
      color: #666666;
      font-family: 'Fira Sans', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      text-transform: capitalize;
      width: 55%;
    }
    &__value {
      color: $text-black;
      font-family: 'Fira Sans', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-left: 8px;
      display: block;
      width: 45%;
      text-align: center;
    }
  }
}
