$main-bg: #fffcee;
$green: #0e5b4c;
$text-black: #0b0b0b;
$mobile: 479.98px;
$tablet: 767.98px;
$desktop: 1279.98px;
$accent: #ebf2e9;

@mixin for-phone-mini {
  @media (max-width: 380px) {
    @content;
  }
}

@mixin for-phone-middle {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin for-phone-top {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-low {
  @media (max-width: 680px) {
    @content;
  }
}

@mixin for-tabled-default {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin for-tablet-middle {
  @media (max-width: 1250px) {
    @content;
  }
}

@mixin for-tablet-upper-middle {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin for-tablet-high {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin for-desktop-slim {
  @media (max-width: 1500px) {
    @content;
  }
}

@mixin for-desktop-low {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin for-desktop-middle {
  @media (max-width: 1650px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (max-width: 1800px) {
    @content;
  }
}

@mixin for-desktop-high {
  @media (max-width: 1850px) {
    @content;
  }
}

@mixin for-desktop-wide {
  @media (max-width: 1880px) {
    @content;
  }
}

@mixin for-low-hight {
  @media (max-height: 850px) and (min-width: 1201px) {
    @content;
  }
}

@mixin for-overlandscape {
  @media (min-width: 1201px) {
    @content;
  }
}

@mixin for-overdesktop {
  @media (min-width: 2000px) {
    @content;
  }
}

@mixin for-overwidth {
  @media (min-width: 2200px) {
    @content;
  }
}
