@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');
@import './utils/vars.scss';
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: 'Fira Sans', sans-serif;
}

ul,
ol {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  height: 100%;
}
body {
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Fira Sans', sans-serif;
}

.layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
ul,
ol {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html,
body {
  position: relative;
}

html,
body,
input,
textarea,
button {
  font-family: 'Fira Sans', sans-serif;
}

body {
  background-color: $main-bg;
}

button {
  cursor: pointer;
  background: none;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}
