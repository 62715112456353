@import 'utils/vars.scss';

#root {
  min-height: 100%;
  height: 100%;
}
.footer {
  background-color: $accent;
  width: 100%;
  padding: 20px 0px;
  flex: 0 1 auto;
  margin-top: auto;
  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;

    @include for-phone-only {
      gap: 8px;
    }
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__top {
    display: flex;
    align-items: center;

    gap: 24px;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 5px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: $text-black;
  }
  &__copyright {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-top: 24px;
  }
}
