@import 'utils/vars.scss';

.main {
  &__hero {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include for-tabled-default {
      height: calc(100% - 70px);
      padding-bottom: 50px;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  &__title {
    text-align: center;
    margin-top: 8%;
    background: linear-gradient(
      180deg,
      #00baf5 0%,
      #a4e9ff 25%,
      #fff9e6 50%,
      #fee27e 75%,
      #b78f00 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Merienda', serif;
    z-index: -1;
    position: relative;
    font-size: 56px;
    line-height: 84px;
    font-weight: 400;
    @include for-tablet-landscape-up {
      margin-top: 100px;
    }
    @include for-tablet-portrait-up {
      margin-top: 80px;
      font-size: 50px;
      line-height: 64px;
    }
    @include for-tabled-default {
      padding: 0px 15px;
    }
    @include for-phone-only {
      margin-top: 65px;
    }
    @media screen and (max-width: 800px) {
      font-size: 40px;
      line-height: 56px;
    }
  }

  &__gif {
    margin: 0 auto;
    margin-top: 5%;
    img {
      width: 300px;
    }
    @include for-tabled-default {
      img {
        width: 200px;
      }
      margin-top: 70px;
      position: relative;
      z-index: -1;
    }

    @include for-tablet-portrait-up {
      margin-top: 80px;
    }
    @include for-phone-only {
      margin-bottom: 30px;
      width: auto;
      height: auto;
    }
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
