@import 'utils/vars.scss';
.contact-form {
  text-align: center;
  padding-bottom: 100px;
  &__text {
    text-align: center;
    color: $text-black;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 34px;
  }
  &__form {
    //  width: 590px;
    margin: 0 auto;

    .ant-form-item {
      margin-bottom: 10px;
    }
  }
  &__wrapper {
    padding: 50px 10px 50px 10px;
    background-image: url('../../../../img/cont.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__form-wrap {
    max-width: 400px;
    margin: 0 auto;
    background-color: #fffcee;
    padding: 16px;
    border-radius: 20px;
  }
}
