@import '../../../../utils/vars.scss';

.header {
  width: 100%;
  padding: 20px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: $accent;
  @include for-tabled-default {
    padding: 10px;
  }
}

.menu {
  display: flex;
  align-items: center;
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    @include for-tabled-default {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      gap: 20px;
      background-color: $accent;

      transform: translateY(-100%);
    }
  }
  &__item {
    padding: 0px 28px;

    @include for-tabled-default {
      padding: 0px;
      &:nth-child(1) {
        .menu__link {
          padding-right: 0px;
        }
      }
      &:nth-child(2) {
        padding: 0px;
        .menu__link {
          padding-right: 0px;
        }
      }
      &:nth-child(3) {
        padding: 0px;
        .menu__link {
          padding-left: 0px;
        }
      }
      &:nth-child(4) {
        .menu__link {
          padding-left: 0px;
        }
      }
    }
  }
  &__link {
    color: $text-black;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding: 8px 0px;
    text-transform: uppercase;
    font-family: 'Playfair Display', serif;
    position: relative;
    transition: all 0.3s ease;
    @include for-tabled-default {
      padding: 4px 0px;
    }
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $text-black;
    }
    &.active {
      color: $green;

      &::after {
        height: 2px;
      }
    }
    &:hover {
      color: $green;
      &::after {
        background-color: $green;
      }
    }
  }
  &__logo {
    width: 60px;
    height: 60px;
    position: relative;
    z-index: 1;
    @include for-tabled-default {
      width: 50px;
      height: 50px;
    }
  }

  .hamburger {
    display: block;
    position: absolute;
    top: 25px;
    right: 12px;
    width: 18px;
    height: 16px;
    cursor: pointer;
    z-index: 5;
    display: none;
    @include for-tabled-default {
      display: block;
      top: 30px;
      right: 25px;
    }
  }
  .hamburger span {
    transition: all 0.3s ease 0s;
    top: 6px;
    left: 0px;
    position: absolute;
    width: 16px;
    height: 2px;

    background: linear-gradient(
      270deg,
      #004a3c 0%,
      #007761 25%,
      #03c9a5 50%,
      #007761 75%,
      #004a3c 100%
    );
  }
  .hamburger span:first-child {
    width: 12px;
    top: 0px;
  }
  .hamburger span:last-child {
    top: auto;
    bottom: 2px;
    width: 8px;
  }
  .hamburger.active span {
    transform: scale(0);
  }
  .hamburger.active span:first-child {
    transform: rotate(-45deg);
    top: 5px;
    width: 15px;
  }
  .hamburger.active span:last-child {
    transform: rotate(45deg);
    bottom: 9px;
    width: 15px;
  }
}

.navigation {
  margin-left: 18%;
  @media screen and (max-width: 1000px) {
    margin-left: 10%;
  }
  @media screen and (max-width: 800px) {
    margin-left: 5%;
  }
}
.navigation--opened {
  .menu__list {
    @include for-tabled-default {
      transform: translateY(0);
    }
  }
}
