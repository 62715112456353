@import 'utils/vars.scss';

.popular-commodities {
  padding: 100px 0px 0px 0px;
  position: relative;

  @media screen and (max-width: 1100px) {
    padding: 50px 0px 0px 0px;
  }
  &__container {
    text-align: center;
  }
  &__list {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }
  &__item {
    flex: 0 1 calc(25% - 18px);
    position: relative;

    @media screen and (max-width: 1000px) {
      flex: 0 1 calc(50% - 12px);
    }
    @media screen and (max-width: 630px) {
      flex: 0 1 100%;
    }
  }
}

.title-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &__link {
    position: absolute;
    top: 17px;
    right: 0;
    @media screen and (max-width: 620px) {
      position: static;
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 620px) {
    flex-direction: column;

    .section-title {
      margin-bottom: 70px;

      @media screen and (max-width: 620px) {
        margin-bottom: 25px;
      }
    }
  }
}

.position {
  &__link {
    width: 100%;
    height: 100%;
    display: block;
    padding-top: 24px;
    background-color: #ebf2e9;
    border-radius: 8px;
    box-shadow: 1px 1px 4px 0px #00000040;
  }
  &__title {
    text-align: center;
    color: $text-black;
    font-weight: 700;
    font-size: 24;
    line-height: 31px;
    margin-bottom: 24px;
  }

  &__button {
    width: 100%;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    background: linear-gradient(180deg, #005444 0%, #009376 50%, #005444 100%);
    color: white;
    font-weight: 700;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    line-height: 23px;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease-in-out;
    &:hover {
      color: $text-black;
    }
  }
}
.position__img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  padding: 0px 24px;
  perspective: 1000px;
  height: 210px;
}

.position__img-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 100%;
}

.position__image,
.position__sub-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition:
    transform 0.6s ease,
    opacity 0.6s ease;
  backface-visibility: hidden; /* Скрываем заднюю сторону при повороте */
}

.position__sub-image {
  opacity: 0; /* Скрываем заменяющее изображение по умолчанию */
  transform: rotateY(-180deg); /* Поворачиваем его на 180 градусов */
}

.position__img-container:hover .position__image {
  transform: rotateY(180deg) translateZ(50px); /* Поворачиваем основное изображение */
  opacity: 0;
}

.position__img-container:hover .position__sub-image {
  transform: rotateY(0deg) translateZ(50px); /* Выводим заменяющее изображение вперед */
  opacity: 1;
}
