@import 'utils/vars.scss';

.bg {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  img {
    object-fit: cover;
  }
}

.contacts {
  .section-title {
    margin-bottom: 16px;
  }
  text-align: center;
  &__sub-title {
    font-family: 'Playfair Display', serif;
    font-size: 36px;
    line-height: 48px;
    color: $text-black;
    margin-bottom: 40px;
  }
  &__text {
    margin-bottom: 40px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    margin-bottom: 75px;
  }
  &__item {
    flex: 0 1 calc(33% - 13px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding-bottom: 28px;
  }
  &__name {
    color: #0e5b4c;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
  }
  &__link {
    color: $text-black;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
    &:visited,
    &:active,
    &:focus,
    &:hover {
      color: $text-black;
    }
  }
}
