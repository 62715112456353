@import 'utils/vars.scss';

.advantages {
  display: flex;
  gap: 24px;
  margin-bottom: 44px;
  @include for-phone-only {
    flex-direction: column;
    gap: 12px;
  }
  &__text {
    flex: 0 1 calc(50% - 12px);
    font-size: 18px;
    line-height: 24px;
    font-family: 'Fira Sans', sans-serif;
    color: #0b0b0b;
    @include for-phone-only {
      flex: 1 1 100%;
      font-size: 16px;
      line-height: 20px;
      color: $text-black;
    }
  }
  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;
  }
  &__title {
    color: $text-black;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Fira Sans', sans-serif;
    text-align: center;
  }
}

.digit {
  display: inline-block;
  animation: spin 1s ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotateX(90deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
