@import 'utils/vars.scss';

.about-us {
  padding: 100px 0px 0px 0px;

  position: relative;

  @media screen and (max-width: 1100px) {
    padding: 50px 0px 0px 0px;
  }
  &__container {
    text-align: center;
  }

  &__grid {
    background: url('../../../../img/steps.svg') no-repeat 70px 0px;
    background-size: contain;
    height: 650px;
    @media screen and (max-width: 1000px) {
      height: 450px;
      background: url('../../../../img/steps-mobile.svg') no-repeat -15px 0px;
      background-size: contain;
    }
  }
  &__text {
    position: absolute;
    bottom: 0%;
    left: 30%;
    width: 60%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    span {
      text-align: center;
      color: $text-black;
      font-family: 'Fira Sans', sans-serif;
      font-size: 18px;
      line-height: 24px;
    }
    @media screen and (max-width: 1100px) {
      bottom: -2%;
    }

    @media screen and (max-width: 1000px) {
      position: static;
      width: 100%;
    }
    @media screen and (max-width: 850px) {
      margin-top: -35px;
    }
    @include for-tabled-default {
      margin-top: -100px;
    }
    @include for-phone-only {
      margin-top: -150px;
    }
    @include for-phone-top {
      margin-top: -200px;
    }
    @include for-phone-middle {
      margin-top: -250px;
    }
  }
}

.section-title {
  font-family: 'Playfair Display', serif;
  font-size: 56px;
  line-height: 56px;
  font-weight: 600;
  color: $text-black;
  margin-bottom: 40px;
  @include for-tablet-landscape-up {
    font-size: 48px;
    line-height: 48px;
  }
  @include for-tabled-default {
    font-size: 40px;
    line-height: 40px;
  }
  @include for-phone-only {
    font-size: 32px;
    line-height: 32px;
  }
}
