.news-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 75px;
}

@media (max-width: 767px) {
  .news-cards {
    grid-template-columns: repeat(2, 1fr); /* 2 колонки */
  }
}

/* Для экранов до 480px шириной */
@media (max-width: 480px) {
  .news-cards {
    grid-template-columns: 1fr; /* 1 колонка */
  }
}

.news-card {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0px #00000040;
  background-color: #f2eadb;
  &__link {
    color: blue;
    font-weight: 600;
  }
}

.news-card h3 {
  margin-bottom: 8px;
}

.news-card p {
  margin-bottom: 12px;
}
