// .rolling-number {
//   display: flex;
// }

// .rolling-number__digit {
//   overflow: hidden;
//   height: 36px; /* Высота одной цифры */
//   width: 20px; /* Ширина одной цифры */
//   position: relative;
// }

// .rolling-number__digit-inner {
//   transition: transform 1s ease-in-out;
//   position: absolute;
//   top: 0;
// }

// .rolling-number__digit-item {
//   height: 36px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 32px; /* Размер цифры */
//   color: #ffffff;
//   background: linear-gradient(
//     90deg,
//     #383635 -12.41%,
//     #6e6b68 17.16%,
//     #b4b4b4 46.72%,
//     #6e6b68 76.28%,
//     #383635 105.84%
//   );
// }
.animated-number {
  display: flex;
  gap: 4px;
}

.animated-digit {
  overflow: hidden;
  width: 58px;
  height: 72px;
  position: relative;
  background: linear-gradient(
    90deg,
    #383635 -12.41%,
    #6e6b68 17.16%,
    #b4b4b4 46.72%,
    #6e6b68 76.28%,
    #383635 105.84%
  );
  border-radius: 50%/100%;
}

.digit-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  div {
    height: 72px;
    line-height: 72px;
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 56px;
    font-family: 'Fira Sans', sans-serif;
  }
}

.dot {
  width: 16px;
  height: 16px;
  background: linear-gradient(
    90deg,
    #383635 -12.41%,
    #6e6b68 17.16%,
    #b4b4b4 46.72%,
    #6e6b68 76.28%,
    #383635 105.84%
  );
  border-radius: 50%;
  align-self: flex-end;
}
