@import 'utils/vars.scss';

.global {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 150px;
  background-color: black;
  &__placeholder {
    color: white;
    font-family: 'Roboto';
    font-style: italic;
    margin-top: 15px;
    text-align: center;
    @include for-tabled-default {
      margin-bottom: 80px;
      padding: 0px 15px;
    }
    @include for-phone-only {
      margin-bottom: 75px;
    }
  }
  @include for-phone-only {
    padding-top: 75px;
  }
  .main__title {
    z-index: 5;
    line-height: 82px;
  }
  .main__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  img {
    width: 250px;
    height: 250px;
    @media screen and (max-width: 700px) {
      width: 200px;
      height: 200px;
    }
  }
}
