@import 'utils/vars.scss';

.commodities {
  &__bg {
    max-height: 450px;
    height: 450px;
    @media screen and (max-width: 768px) {
      height: 300px;
    }
    img {
      width: 100%;
      max-height: 450px;
      height: 100%;
      @media screen and (max-width: 768px) {
        max-height: 300px;
      }
    }
  }
  &__container {
    text-align: center;
  }
  .popular-commodities__list {
    margin-bottom: 75px;
  }
  &__contact {
    margin-bottom: 100px;
  }
  &__contact-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    font-family: 'Playfair Display', sans-serif;
    color: $text-black;
    margin-bottom: 24px;
  }
  &__contact-text {
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: $text-black;
    margin-bottom: 24px;
  }
  &__contact-btn {
    background: linear-gradient(180deg, #005444 0%, #009376 50%, #005444 100%);
    padding: 8px 12px;
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    border-radius: 8px;
  }
}
